<template>
  <ServiceRadioWithMarketSegmentFormPart
    :choices="services"
    v-on="$listeners"
  />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import { accountancyDefaultNL } from 'chimera/accountancy/service'
import ServiceRadioWithMarketSegmentFormPart from 'chimera/all/components/form/parts/serviceMarketSegment/ServiceRadioWithMarketSegmentFormPart.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioWithMarketSegmentFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      services: [
        new Selectable('Particulier', 'Particulier', accountancyDefaultNL.id),
        new Selectable('Zakelijk', 'Zakelijk', accountancyDefaultNL.id),
      ],
    }
  },
}
</script>
